/* Forms */

.form-group {
    margin-bottom: 1.5rem;
}

.input-group-append,
.input-group-prepend {
    color: $input-placeholder-color;
    width: auto;
    border: none;

    .input-group-text {
        border-color: $border-color;
        padding: 0.575rem 0.75rem;
        color: $input-placeholder-color;
    }

    button {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
    }
}

.custom-control {
    .custom-control-label {
        line-height: 1.6;
        margin-bottom: 0;
    }
}

.form-control {
    border: 1px solid $border-color;
    font-size: $input-font-size;
}

select {
    &.form-control {
        padding: 0.4375rem 0.75rem;
        border: 0;
        outline: 1px solid $border-color;
        color: $input-placeholder-color;

        &:focus {
            outline: 1px solid $border-color;
        }

        @each $color, $value in $theme-colors {
            &.border-#{$color} {
                outline: 1px solid $value;

                &:focus {
                    outline: 1px solid $value;
                }
            }
        }
    }
}

.form-group {
    label {
        font-size: $default-font-size;
        line-height: 1;
        vertical-align: top;
        margin-bottom: 0.5rem;
    }

    &.has-danger {
        .form-control {
            border-color: theme-color(danger);
        }
    }

    .file-upload-default {
        visibility: hidden;
        position: absolute;
    }

    .file-upload-info {
        background: transparent;
    }
}

.custom-file {
    .visibility-hidden {
        visibility: hidden;
    }

    .custom-file-label {
        background: $input-bg;
        border: 1px solid $border-color;
        height: calc(2.25rem + 2px);
        font-weight: normal;
        font-size: 0.875rem;
        padding: 0.625rem 0.6875rem;
        border-radius: 2px;

        &:after {
            background-color: theme-color(primary);
            height: auto;
            @extend .btn-primary;
        }
    }
}

.error-text {
    color: $pink;
    font-size: 0.7rem !important;
    letter-spacing: 0.5px;
    max-width: 70%;
}

.ql-container.ql-snow {
    min-height: 250px !important;

    .ql-editor {
        max-height: 400px !important;
    }
}

.custom-selector > div {
    border: 1px solid #ebedf2;
    min-height: 44px;

    &:hover {
        border: 1px solid #ebedf2;
    }
}

.custom-selector {
    .css-1pahdxg-control {
        // box-shadow: 0 0 0 1px #f8d5c5;
        box-shadow: none;
        border: 1px solid #80bdff;
    }

    .css-1wa3eu0-placeholder {
        color: #c9c8c8;
        font-size: 1rem;
    }
}

// -------------------------------------------- fieldText
.input-wrapper {
    position: relative;
}

.eye-wrapper {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}
