.Pagination-wrapper {
  display             : flex;
  justify-content     : flex-end;
  align-items         : center;
  // padding: 1em;
  // padding-bottom: 5em;
  padding             : 0.5rem 0;
  // border-top       : 1px solid #cecece;
  margin-top          : 1rem;
}

.paginationIcon {
  display        : flex;
  justify-content: center;
  align-items    : center;

  &.activated {
    // @include theme-aware("background-color", "blue-6");
    // @include theme-aware("color", "white-static-1");
    // background-color: #000;
    // color: #fff;
    // font-weight: bold;
    // font-size  : 17px;
    // background-color: #51A6F5;
    background-color: #3772FF;
    color           : #fff;
  }

  &.hidden-element {
    opacity: 0;
    z-index: -1;
  }

  width            : 30px;
  height           : 30px;
  background-color : transparent;
  margin           : 0.5rem;
  border-radius    : 4px;
  // @include theme-aware("color", "blue-6");
  color            : #000;
  cursor           : pointer;
  background-color : #ececec;

  &.translateR:hover {
    transform: translateX(-2px);
  }

  &.translateL:hover {
    transform: translateX(2px);
  }

  &.translateT:hover {
    // transform: translateY(-2px);
    background-color: #cecece;
  }

  i {
    font-size  : 0.8rem;
    font-weight: 100;
  }
}

.transition0-2 {
  transition: all 0.2s ease-in-out;
}

@media (max-width: 450px) {
  .paginationIcon {
    &.translateR {
      display: none;
    }

    &.translateL {
      display: none;
    }
  }
}