/* React Bootstrap Tables */

.react-bootstrap-table {
  table {
    &.table {
      table-layout: auto;

      thead {
        th {
          position  : relative;
          border-top: 1px solid $border-color;
          padding   : $table-cell-padding 2.3rem $table-cell-padding $table-cell-padding;

          &:focus {
            outline: none;
          }

          .order-4,
          .caret-4-asc,
          .caret-4-desc {
            position    : absolute;
            right       : 10px;
            padding-left: 15px;
          }
        }
      }

      .btn {
        padding       : 0.1rem 1rem;
        vertical-align: top;

        i {
          font-size   : .875rem;
          margin-right: .3rem;
        }
      }
    }
  }

  &.orders-table {
    thead {
      tr {
        background: theme-color(primary);

        th {
          border: 0;
          color : #fff;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.react-bootstrap-table-pagination {
  margin-top : 15px;
  align-items: flex-end;

  .react-bs-table-sizePerPage-dropdown {
    .dropdown-toggle {
      padding: 0.6rem 2.5rem;
      @extend .btn-primary;
    }
  }

  .react-bootstrap-table-pagination-list {
    .pagination {
      margin-bottom  : 0;
      justify-content: flex-end;
    }
  }
}