.galleryComponent {}

.titleWrapper {
    width          : 100%;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-bottom  : 0.5rem;
}

.thumbnailWrapper {
    padding: 5px;
    border : 1px solid #ebedf2;

    img {
        width     : 100%;
        max-height: 200px;
        object-fit: contain;
    }

    video {
        width     : 100%;
        max-height: 350px;
    }
}