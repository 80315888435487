.dragAndDropWrapper {
    position       : relative;
    width          : 100%;
    height         : 100%;
    min-height     : 100px;
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    border         : 1px dashed #b0b0b0;
    padding        : 1rem 0;
    margin-top     : 1rem;
}

.dragAndDropOverlay {
    position        : absolute;
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100%;
    background-color: rgba(215, 214, 214, 0.8);
    display         : flex;
    justify-content : center;
    align-items     : center;
    z-index         : 1;

    p {
        color     : #333;
        font-size : 1.5rem;
        text-align: center;
    }
}