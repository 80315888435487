.mainWrapper {
    width : 100%;
    height: 100%;
}

.preLoad {
    width          : 100%;
    height         : 100%;
    min-height     : 300px;
    display        : flex;
    justify-content: center;
    align-items    : center;
}

.flexWrapper {
    display        : flex;
    justify-content: space-between;
}

.galleryWrapper {
    display              : grid;
    grid-template-columns: repeat(auto-fill, minmax(302px, 1fr));
    width                : 100%;
    margin-top           : 2rem;
    gap                  : 10px;
    grid-auto-rows       : max-content;
}

.halfSizeWrapper {
    width     : 70%;
    flex-basis: 70%;
}

.imageWrapper {
    position: relative;
    width   : 100%;
    height  : 200px;

    img {
        width     : 100%;
        height    : 100%;
        object-fit: contain;
        cursor    : pointer;
    }
}


.imageOverlay {
    position        : absolute;
    top             : 0;
    left            : 0;
    width           : 100%;
    height          : 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display         : flex;
    justify-content : center;
    align-items     : center;
    text-align      : center;

    i {
        font-size: 5rem;
        color    : #fff;
    }
}

.extraInformationWrapper {
    margin-top: 1rem;
}

.imageDetailsWrapper {
    position        : sticky;
    top             : 5rem;
    margin-top      : 2rem;
    background-color: rgba(198, 198, 198, 0.3);
    padding         : 0.5rem;
    margin-left     : .5rem;
    height          : 70vh;
    border-radius   : 7px;
    width           : 0;
    opacity         : 0;
    visibility      : hidden;
    overflow        : hidden;
    transition      : opacity .1s ease;
    overflow-y      : auto;

    &_imageWrapper {
        position  : relative;
        width     : 100%;
        min-height: 150px;

        img {
            width     : 100%;
            height    : 100%;
            object-fit: cover;
        }
    }

    &_button {
        border          : none;
        background-color: rgba(255, 255, 255, 0.8);
        width           : 34px;
        height          : 34px;
        display         : flex;
        align-items     : center;
        justify-content : center;
        border-radius   : 50%;
        position        : absolute;
        top             : 5px;
        left            : 5px;
        transition      : .3s ease;

        &:hover {
            background-color: rgba(255, 255, 255, 1);
        }

    }

    .nexPrevBtnWrapper {
        position          : absolute;
        top               : 5px;
        right             : 5px;
        display           : flex;
        align-items       : center;
        // justify-content: center;

        button {
            border          : none;
            background-color: rgba(255, 255, 255, 0.8);
            width           : 24px;
            height          : 24px;
            border-radius   : 50%;
            transition      : .3s ease;
            display         : flex;
            align-items     : center;
            justify-content : center;

            &:hover {
                background-color: rgba(255, 255, 255, 1);
            }

            &:first-child {
                margin-right: 5px;
            }
        }
    }

    &_detailsWrapper {
        margin-top    : 1rem;
        display       : flex;
        flex-direction: column;
        width         : 100%;

        >div {
            width: 100%;
        }

        p,
        h4 {
            margin        : 0;
            text-transform: capitalize;
        }

    }
}

.showDetailsWrapper {
    opacity   : 1;
    width     : 30%;
    visibility: visible;
    transition: opacity .4s ease-in-out .1s;
}

.btnWrapper {
    margin-top: 1rem;
}


.noDataWrapper {
    display        : flex;
    flex-direction : column;
    justify-content: center;
    align-items    : center;
    height         : 100%;
    width          : 100%;

    h2 {
        color      : #9c9fa6;
        font-weight: normal;
    }
}